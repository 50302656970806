<script lang="ts">
    import C9IconBase from "$lib/components/C9Icon/C9IconBase.svelte";
</script>

<C9IconBase {...$$props}>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 496.551 496.551" style="enable-background:new 0 0 496.551 496.551;" xml:space="preserve">
<g>
	<g>
		<g>
			<path d="M496.547,120.326v-48c0-2.122-0.844-4.156-2.344-5.656l-64-64c-3.125-3.124-8.19-3.123-11.314,0.002
				c-1.499,1.5-2.342,3.534-2.342,5.654v52.688l-4.096,4.096c-22.161-20.074-47.914-35.784-75.904-46.304V8.326
				c-0.001-4.418-3.583-7.999-8.002-7.998c-2.121,0-4.154,0.843-5.654,2.342l-7.072,7.072
				c-131.756-37.42-268.9,39.054-306.32,170.809c-37.42,131.756,39.054,268.9,170.809,306.32
				c45.907,13.038,94.6,12.554,140.239-1.394v2.848h16v-304c0-2.122-0.844-4.156-2.344-5.656l-30.592-30.592l0.096-0.176
				l-0.536-0.264l-30.624-30.624V75.638l48-48v36.688c0,2.122,0.844,4.156,2.344,5.656l48,48c3.124,3.123,8.188,3.123,11.312,0
				l48-48c1.5-1.5,2.344-3.534,2.344-5.656V27.638l48,48v41.376l-61.656,61.656c-1.5,1.5-2.344,3.534-2.344,5.656v304h16V419.31
				c66.295-75.093,82.625-181.986,41.776-273.448l19.88-19.88C495.704,124.482,496.547,122.448,496.547,120.326z M320.547,379.734
				c-2.16,1.28-4.32,2.544-6.536,3.704l-40.392-94.256c7.384-4.539,13.409-10.983,17.44-18.656l29.488,14.744V379.734z
				 M207.691,223.262l-96-41.16c14.74-30.999,39.586-56.063,70.456-71.072l41.328,96.44
				C217.054,211.431,211.648,216.839,207.691,223.262z M248.547,216.326c17.673,0,32,14.327,32,32s-14.327,32-32,32
				c-17.673,0-32-14.327-32-32S230.874,216.326,248.547,216.326z M201.675,238.078c-1.504,6.749-1.504,13.747,0,20.496l-96.144,41.2
				c-5.913-16.51-8.952-33.911-8.984-51.448c0.032-17.537,3.071-34.938,8.984-51.448L201.675,238.078z M207.691,273.39
				c3.957,6.424,9.363,11.832,15.784,15.792l-41.328,96.44c-30.882-15.004-55.74-40.068-70.488-71.072L207.691,273.39z
				 M238.307,295.198c6.744,1.504,13.736,1.504,20.48,0l40.664,94.872c-32.829,13.052-69.294,13.667-102.544,1.728L238.307,295.198z
				 M320.547,267.382l-24.536-12.272c0.718-4.505,0.718-9.095,0-13.6l24.536-12.24V267.382z M320.547,187.638v23.744l-29.488,14.744
				c-3.989-7.591-9.928-13.981-17.208-18.512l22.224-44.448L320.547,187.638z M284.147,151.238l-25.136,50.272
				c-6.811-1.56-13.885-1.579-20.704-0.056l-41.4-96.6c15.663-5.61,32.171-8.495,48.808-8.528c3.632,0,7.2,0.28,10.832,0.544v23.456
				c0,2.122,0.844,4.156,2.344,5.656L284.147,151.238z M258.891,66.67c-1.5,1.5-2.344,3.534-2.344,5.656v8.544
				c-3.608-0.24-7.2-0.544-10.832-0.544c-92.784,0-168,75.216-168,168s75.216,168,168,168c26.047-0.011,51.711-6.278,74.832-18.272
				v70.704c-121.769,39.785-252.734-26.675-292.52-148.444S54.703,67.58,176.472,27.794c40.83-13.34,84.562-15.081,126.323-5.028
				L258.891,66.67z M376.547,101.014l-40-40V35.91c23.703,9.68,45.549,23.395,64.568,40.536L376.547,101.014z M480.547,250.206
				c0.121,51.948-16.734,102.514-48,144V187.638l29.6-29.64C474.351,187.205,480.606,218.553,480.547,250.206z"/>
			<path d="M376.547,200.326c-7.396-1.099-13.816-5.678-17.264-12.312l-13.472,8.624c6.125,11.46,17.764,18.916,30.736,19.688
				c12.972-0.772,24.611-8.228,30.736-19.688l-13.472-8.624C390.363,194.649,383.943,199.227,376.547,200.326z"/>
			<rect x="384.547" y="232.326" width="16" height="256"/>
			<rect x="396.947" y="116.351" transform="matrix(0.7071 -0.7071 0.7071 0.7071 39.9312 345.1043)" width="79.192" height="16"/>
			<path d="M32.547,248.326h16c0.123-110.406,89.594-199.877,200-200v-16C129.308,32.459,32.679,129.088,32.547,248.326z"/>
			
				<rect x="152.551" y="141.013" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 166.3671 373.5627)" width="16" height="22.624"/>
			<rect x="141.234" y="328.331" transform="matrix(0.7071 0.7071 -0.7071 0.7071 282.5015 -9.3577)" width="22.624" height="16"/>
		</g>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

</C9IconBase>
