'use strict';
import { asArray, defaultEmpty, isNotEmpty, isStringNotEmpty } from '@rigu/js-toolkit';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import type { WorkingDaysModel } from './week.models';

export enum RoundTripType {
    DEPARTURE = 'departure',
    RETURN = 'return'
}

export const formatPhoneNumber = (phone: string) => {
    if (isStringNotEmpty(phone)) {
        const phoneNumber = parsePhoneNumberFromString(phone);
        const formatInternational = phoneNumber?.formatInternational() ?? phone;
        return isStringNotEmpty(formatInternational) ? formatInternational : phone;
    }
    return '';
};

export interface PhoneModel {
    dialCode: string;
    phone: string;
    internationalFormat: string;
}

export class Phone implements PhoneModel {
    dialCode: string;
    phone: string;

    internationalFormat: string;

    constructor(dialCode: string = '', phone: string) {
        this.dialCode = defaultEmpty(dialCode);
        this.phone = defaultEmpty(phone);

        this.internationalFormat = formatPhoneNumber(this.dialCode + this.phone);
    }

    get notFormatted(): string {
        return defaultEmpty(this.dialCode) + defaultEmpty(this.phone);
    }

    get phoneNumber(): string {
        return [this.dialCode, this.phone].filter(isStringNotEmpty).join('');
    }
}

export interface AddressModel {
    country?: string;
    town?: string;
    street?: string;
    streetNo?: string;
    postcode?: string;
    box?: string;

    cityLocation?: {
        code: string;
        country: string;
        name: string;
    };
}

export class Address implements AddressModel {
    country: string;
    town: string;
    street: string;
    streetNo: string;
    postcode: string;
    box: string;

    constructor(
        country: string = '',
        town: string = '',
        street: string = '',
        streetNo: string = '',
        postcode: string = '',
        box: string = '',
    ) {
        this.country = defaultEmpty(country);
        this.town = defaultEmpty(town);
        this.street = defaultEmpty(street);
        this.streetNo = defaultEmpty(streetNo) + '';
        this.box = defaultEmpty(box);
        this.postcode = defaultEmpty(postcode) + '';
    }

    get streetAndNo() {
        return [this.street.trim(), this.streetNo.trim()].filter(isStringNotEmpty).join(' ');
    }

    get postcodeTown() {
        return [this.postcode, this.town].filter(isStringNotEmpty).join(' ');
    }

    get postcodeTownAndCountry() {
        return [this.postcodeTown, this.country].filter(isStringNotEmpty).join(', ');
    }

    get cityLocation() {
        return {
            code: this.country + ':' + this.town,
            country: this.country,
            name: this.town,
        };
    }
}

export interface ContactModel {
    phones?: PhoneModel[];
    emails?: string[];
    facebook?: string[];
    web?: string[];

    hasData?: boolean;
}

export class Contact implements ContactModel {
    phones: PhoneModel[];
    emails: string[];
    facebook: string[];
    web: string[];

    constructor(phones: PhoneModel[], emails: string[], facebook: string[], web: string[]) {
        this.phones = asArray(phones);
        this.emails = asArray(emails);
        this.facebook = asArray(facebook);
        this.web = asArray(web);
    }

    get hasEmails(): boolean {
        return isNotEmpty(this.emails);
    }

    get hasPhones(): boolean {
        return isNotEmpty(this.phones);
    }

    get hasFacebook(): boolean {
        return isNotEmpty(this.facebook);
    }

    get hasWeb(): boolean {
        return isNotEmpty(this.web);
    }

    get hasData(): boolean {
        return this.hasPhones || this.hasEmails || this.hasFacebook || this.hasWeb;
    }
}

export interface LocationModel {
    address?: AddressModel;
    contact?: ContactModel;
    workingDays?: WorkingDaysModel;
    roundTrip?: RoundTripType;
    timetable?: string[];

    hasContactData?: boolean;
    town?: string;
}

export class EntityLocation implements LocationModel {
    address: AddressModel;
    contact: ContactModel;
    workingDays: WorkingDaysModel;
    roundTrip: RoundTripType;
    timetable: string[];

    constructor(
        address: AddressModel,
        contact: ContactModel,
        workingDays: WorkingDaysModel,
        roundTrip: RoundTripType,
        timetable: string[],
    ) {
        this.address = address;
        this.contact = contact;
        this.workingDays = workingDays;
        this.roundTrip = roundTrip;
        this.timetable = asArray(timetable);
    }

    get hasContactData(): boolean {
        return this.contact?.hasData === true;
    }

    get country(): string {
        return this.address?.country ?? '';
    }

    get town(): string {
        return this.address?.town ?? '';
    }

    get city(): string {
        return this.town;
    }

    get cityLocation() {
        return this.address?.cityLocation ?? null;
    }
}
