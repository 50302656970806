import { asArray, isNotEmpty } from '@rigu/js-toolkit';

export interface ScreenSizeModel {
	height: number;
	width: number;
}

export interface SelectOption {
	code: string;
	name: string;
	group?: string;
}

export interface TokenizedModel {
	tokens?: string[];
	lastTokenExists?: boolean;
}

export class Tokenized {
	tokens: string[];
	lastTokenExists: boolean = false;

	constructor(tokens: string[] | undefined) {
		this.tokens = asArray(tokens).map((token: string) => token.toLowerCase());
		this.lastTokenExists = false;
	}

	resetLastTokenExists() {
		this.lastTokenExists = false;
	}

	hasToken(
		tokenToSearch: string | string[],
		persistTokenExist: boolean = true,
		isPreprocessed: boolean = false
	): boolean {
		const toSearch = isPreprocessed
			? asArray(tokenToSearch)
			: asArray(tokenToSearch).map((token: string) => token.trim().toLowerCase());
		const result =
			isNotEmpty(toSearch) &&
			this.tokens.findIndex(
				(token) =>
					toSearch.findIndex((tokenToSearch: string) => token.startsWith(tokenToSearch)) >= 0
			) >= 0;
		this.lastTokenExists = persistTokenExist && result;
		return result;
	}

	static from({ tokens }: Partial<Tokenized>): Tokenized {
		return new Tokenized(tokens);
	}
}

export type TokenizedType = Tokenized;
