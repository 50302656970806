<script lang="ts">
    import C9IconBase from "$lib/components/C9Icon/C9IconBase.svelte";
</script>

<C9IconBase {...$$props}>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 395 395" style="enable-background:new 0 0 395 395;" xml:space="preserve">
<g>
	<path d="M143.591,211.597l52.855-45.456l12.664,23.392c1.977,3.651,5.475,6.234,9.546,7.049l31.483,6.299
		c0.925,0.186,1.847,0.274,2.757,0.274c6.522,0,12.357-4.593,13.686-11.234c1.514-7.567-3.393-14.929-10.96-16.442l-25.057-5.014
		l-24.059-44.439c-1.23-2.617-2.82-5.115-4.797-7.413c-11.74-13.651-32.325-15.202-45.979-3.461l-75.293,64.752
		c-6.478,5.571-10.225,13.134-11.127,21.002l-11.576,87.237h29.988v32.604c0,7.717,6.256,13.973,13.973,13.973
		s13.973-6.256,13.973-13.973v-32.604h38.82L143.591,211.597z"/>
	<circle cx="240.584" cy="102.056" r="34.556"/>
	<path d="M272.53,217.496h-28.204c-1.774,0-3.485,0.27-5.095,0.767c-3.555-3.665-7.289-6.398-9.896-8.088
		c-10.113-6.568-23.871,0.032-26.525,1.421c-4.771,2.497-6.605,8.369-4.13,13.15c2.474,4.782,8.367,6.653,13.163,4.202
		c2.385-1.22,5.812-2.212,7.248-2.098c3.311,2.201,6.099,4.754,8.033,7.272c-0.007,0.203-0.031,0.402-0.031,0.607
		c0,9.518,7.716,17.233,17.233,17.233h31.863c8.904,0,16.229-6.754,17.136-15.419c0.4-1.123,0.63-2.328,0.63-3.589v-24.341
		c0-5.917-4.796-10.712-10.712-10.712s-10.712,4.796-10.712,10.712V217.496z"/>
	<circle cx="316.893" cy="232.956" r="20.372"/>
	<path d="M337.264,260.43H204.056c-3.858,0-6.986,3.128-6.986,6.986V327.5c0,3.858,3.128,6.986,6.986,6.986s6.986-3.128,6.986-6.986
		v-53.097h119.236V327.5c0,3.858,3.128,6.986,6.986,6.986s6.986-3.128,6.986-6.986v-60.083
		C344.25,263.558,341.123,260.43,337.264,260.43z"/>
	<path d="M313.001,0H81.999C36.785,0,0,36.784,0,81.998v230.993C0,358.211,36.785,395,81.999,395h231.002
		C358.216,395,395,358.211,395,312.991V81.998C395,36.784,358.216,0,313.001,0z M380,312.991C380,349.94,349.945,380,313.001,380
		H81.999C45.056,380,15,349.94,15,312.991V81.998C15,45.055,45.056,15,81.999,15h231.002C349.945,15,380,45.055,380,81.998V312.991z
		"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

</C9IconBase>
