<script lang="ts">
    import C9IconBase from "$lib/components/C9Icon/C9IconBase.svelte";
</script>

<C9IconBase {...$$props}>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve">
<g>
	<g>
		<path d="M72,10.16h-8c-35.33,0.04-63.96,28.67-64,64c-0.014,1.238,0.26,2.462,0.8,3.576l31.248,62.408L40,466.352
			c0.104,4.343,3.656,7.809,8,7.808h40c4.344,0.001,7.896-3.465,8-7.808l7.952-326.208l31.2-62.4
			c0.558-1.112,0.848-2.34,0.848-3.584C135.961,38.83,107.33,10.2,72,10.16z M80.192,458.16H55.808l-6.632-272h37.648L80.192,458.16
			z M87.2,170.16H48.8l-0.584-24h39.6L87.2,170.16z M96,120.272V90.16H80v40H56v-56H40v46.112L16,72.336
			c1.013-25.78,22.2-46.162,48-46.176h8c25.8,0.014,46.987,20.396,48,46.176L96,120.272z"/>
	</g>
</g>
<g>
	<g>
		<path d="M359.481,419.04c0.152-0.176,0.36-0.296,0.504-0.472c21.232-26.096-1.664-78.696-52.128-119.744
			S201.24,246.064,180,272.168s1.672,78.696,52.128,119.744c12.521,10.236,26.147,19.04,40.624,26.248H140c-4.418,0-8,3.582-8,8v40
			c0,4.418,3.582,8,8,8h76v-16h-68v-24h200v24h-84v16h92c4.418,0,8-3.582,8-8v-40C363.98,423.118,362.225,420.353,359.481,419.04z
			 M347.529,408.472c-6.624,8.152-21.088,10.264-39.672,5.832c-24.142-6.7-46.536-18.578-65.624-34.808
			c-45.712-37.184-62.944-81.088-49.808-97.232c5.818-5.806,13.922-8.715,22.104-7.936c21.072,0,52.328,11.768,83.2,36.904
			C343.44,348.424,360.664,392.328,347.529,408.472z"/>
	</g>
</g>
<g>
	<g>
		<rect x="232" y="458.16" width="16" height="16"/>
	</g>
</g>
<g>
	<g>
		<path d="M336,391.76c-0.968-10.744-12.312-38.8-48.32-68.088c-30.4-24.712-58.4-33.512-73.144-33.512
			c-1.125-0.005-2.249,0.048-3.368,0.16c-4.348,0.439-7.54,4.286-7.168,8.64c0.976,10.752,12.32,38.816,48.32,68.096
			c30.4,24.72,58.4,33.488,73.128,33.488c1.352,0.016,2.464-0.04,3.352-0.128C333.168,399.994,336.381,396.132,336,391.76z
			 M262.401,354.672L262.401,354.672c-16.548-12.672-30.033-28.905-39.456-47.496c20.124,5.446,38.78,15.312,54.608,28.88
			c16.546,12.675,30.028,28.911,39.448,47.504C296.877,378.116,278.223,368.247,262.401,354.672z"/>
	</g>
</g>
<g>
	<g>
		<path d="M472,298.16v-80c0-4.418-3.582-8-8-8v-49.368c0.002-13.255-10.742-24.001-23.997-24.003
			c-6.365-0.001-12.469,2.527-16.971,7.027l-16,16c-4.52,4.491-7.052,10.605-7.032,16.976v33.368c-4.418,0-8,3.582-8,8v80
			c-4.418,0-8,3.582-8,8v145.336c0,13.255,10.745,24,24,24h48c13.255,0,24-10.745,24-24V306.16
			C480,301.742,476.419,298.16,472,298.16z M416,176.792c-0.002-2.124,0.842-4.162,2.344-5.664l16-16
			c3.125-3.124,8.19-3.123,11.314,0.002c1.501,1.502,2.344,3.539,2.342,5.662v49.368h-32V176.792z M408,226.16h48v72h-48V226.16z
			 M464,451.496c0,4.418-3.582,8-8,8h-48c-4.418,0-8-3.582-8-8V314.16h64V451.496z"/>
	</g>
</g>
<g>
	<g>
		<polygon points="453.656,15.816 442.344,4.504 436,10.848 429.656,4.504 418.344,15.816 424.688,22.16 418.344,28.504 
			429.656,39.816 436,33.472 442.344,39.816 453.656,28.504 447.313,22.16 		"/>
	</g>
</g>
<g>
	<g>
		<polygon points="189.656,159.816 178.344,148.504 172,154.848 165.656,148.504 154.344,159.816 160.688,166.16 154.344,172.504 
			165.656,183.816 172,177.472 178.344,183.816 189.656,172.504 183.313,166.16 		"/>
	</g>
</g>
<g>
	<g>
		<polygon points="365.656,263.816 354.344,252.504 348,258.848 341.656,252.504 330.344,263.816 336.688,270.16 330.344,276.504 
			341.656,287.816 348,281.472 354.344,287.816 365.656,276.504 359.313,270.16 		"/>
	</g>
</g>
<g>
	<g>
		<polygon points="333.656,143.816 322.344,132.504 312,142.848 301.656,132.504 290.344,143.816 300.688,154.16 290.344,164.504 
			301.656,175.816 312,165.472 322.344,175.816 333.656,164.504 323.313,154.16 		"/>
	</g>
</g>
<g>
	<g>
		<polygon points="245.656,15.816 234.344,4.504 224,14.848 213.656,4.504 202.344,15.816 212.688,26.16 202.344,36.504 
			213.656,47.816 224,37.472 234.344,47.816 245.656,36.504 235.313,26.16 		"/>
	</g>
</g>
<g>
	<g>
		<rect x="432" y="338.16" width="16" height="72"/>
	</g>
</g>
<g>
	<g>
		<rect x="432" y="426.16" width="16" height="16"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

</C9IconBase>
