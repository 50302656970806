<script lang="ts">
    import C9IconBase from "$lib/components/C9Icon/C9IconBase.svelte";
</script>

<C9IconBase {...$$props}>
    <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
        <path d="m37 54h-2v-9.8a1 1 0 0 0 -2 0v9.8h-26v-20h8.32a1 1 0 0 0 0-2h-9.32a1 1 0 0 0 -1 1v21h-2a1 1 0 0 0 -1 1v2a5 5 0 0 0 5 5h26a5 5 0 0 0 5-5v-2a1 1 0 0 0 -1-1zm-1 3a3 3 0 0 1 -3 3h-2v-1a1 1 0 0 0 -2 0v1h-18v-1a1 1 0 0 0 -2 0v1h-2a3 3 0 0 1 -3-3v-1h32z"/>
        <path d="m54 8h2a1 1 0 0 0 0-2h-2a3 3 0 0 0 -3 3v1h-1a1 1 0 0 0 0 2h1v3a1 1 0 0 0 2 0v-3h1a1 1 0 0 0 0-2h-1v-1a1 1 0 0 1 1-1z"/>
        <path d="m59 2h-12a3 3 0 0 0 -3 3v2a1 1 0 0 0 2 0v-2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1-1v-6a1 1 0 0 0 -2 0v6a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-12a3 3 0 0 0 -3-3z"/>
        <path d="m49 42a4 4 0 1 0 4-4 4 4 0 0 0 -4 4zm6 0a2 2 0 1 1 -2-2 2 2 0 0 1 2 2z"/>
        <path d="m59 33h-12a3 3 0 0 0 -3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-2a1 1 0 0 0 -2 0v2a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1-1v-12a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6a1 1 0 0 0 2 0v-6a3 3 0 0 0 -3-3z"/>
        <path d="m24.47 13.85a1 1 0 0 0 .53.15 1 1 0 0 0 .45-.11l6-3a1 1 0 0 0 0-1.78l-6-3a1 1 0 0 0 -1.45.89v6a1 1 0 0 0 .47.85zm1.53-5.23 2.76 1.38-2.76 1.38z"/>
        <path d="m27 19a9 9 0 1 0 -9-9 9 9 0 0 0 9 9zm0-16a7 7 0 1 1 -7 7 7 7 0 0 1 7-7z"/>
        <path d="m37 23a1 1 0 0 0 .8-.4l3-4a1 1 0 0 0 -1.6-1.2l-3 4a1 1 0 0 0 .2 1.4 1 1 0 0 0 .6.2z"/>
        <path d="m39.2 26.6a1 1 0 0 0 .8.4 1 1 0 0 0 .6-.2l4-3a1 1 0 1 0 -1.2-1.6l-4 3a1 1 0 0 0 -.2 1.4z"/>
        <path d="m49 28.8a1 1 0 0 0 -1.2-.8l-5 1a1 1 0 0 0 .2 2h.2l5-1a1 1 0 0 0 .8-1.2z"/>
        <path d="m3 29h12a1 1 0 0 0 1-1v-16a1 1 0 0 0 -1-1h-12a1 1 0 0 0 -1 1v16a1 1 0 0 0 1 1zm1-16h10v14h-10z"/>
        <path d="m11 15h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z"/>
        <path d="m11 19h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2z"/>
        <path d="m11 23h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2z"/>
        <path d="m36.12 28.7a1 1 0 0 0 -1.74 1l4.41 7.63-16 6s0 0-.07 0h-.08l-2.6 1.5-1.73 1a2 2 0 0 1 -1.52.2 2 2 0 0 1 -1.21-.93l-2-3.46a2 2 0 0 1 .75-2.64l3.46-2 2.5 4.33a1 1 0 0 0 .87.5 1 1 0 0 0 .87-1.5l-2.58-4.46 12.55-10.35.41.71a1 1 0 1 0 1.74-1l-1-1.73a1 1 0 0 0 -.69-.48 1 1 0 0 0 -.82.21l-14 11.54-4.26 2.46a4 4 0 0 0 -1.84 4.53l-1 .57a3 3 0 0 0 .72 5.5 3 3 0 0 0 .78.1 3.06 3.06 0 0 0 1.5-.4l1-.57a3.88 3.88 0 0 0 1.75 1.04 3.79 3.79 0 0 0 1.05.14 3.94 3.94 0 0 0 2-.54l.87-.5 2 3.47a3 3 0 0 0 4.09 1.1 3 3 0 0 0 1.1-4.1l-1.82-3.17 15-5.63a1 1 0 0 0 .59-.6 1 1 0 0 0 -.07-.83zm-23.62 17.09a1 1 0 0 1 -.76.1 1 1 0 0 1 -.61-.46 1 1 0 0 1 -.1-.76 1 1 0 0 1 .47-.61l.87-.5 1 1.73zm13.16 2.8a1 1 0 0 1 .1.76 1 1 0 0 1 -.47.6 1 1 0 0 1 -1.36-.36l-2-3.47 1.66-.95h.08z"/>
    </svg>
</C9IconBase>
