import { asArray, isNotEmpty } from '@rigu/js-toolkit';
import type { AddressModel, ContactModel, LocationModel } from './contact.models';
import type { WeekDayCode, WorkDayCode, WorkingDaysModel } from './week.models';

export class ServiceModel {
    title: string;
    description: string;

    constructor(title: string, description: string) {
        this.title = title;
        this.description = description;
    }
}

export enum SupplyRadius {
    MAX_1 = 1,
    MAX_5 = 5,
    MAX_10 = 10,
    MAX_20 = 20,
    MAX_50 = 50,
    MAX_POSSIBLE = 9999
}

export interface SupplyOptions {
    accredited?: boolean;
    agreedPrice?: boolean;
    capacity?: number;
    covering?: string;
    delivery?: boolean;
    homeService?: boolean;
    institution?: string[];
    invoice?: boolean;
    licenced?: boolean;
    locations?: string[];
    overtime?: boolean;
    paymentType?: string[];
    provideCertificate?: boolean;
    radius?: SupplyRadius; // number
}

export interface SupplyModel {
    mainService: ServiceModel;
    services: ServiceModel[];
    products: string[];
    workingDays: WorkingDaysModel[];

    location: LocationModel;
    languages: string[];
    options: SupplyOptions;

    allWorkDays: WorkDayCode[];
    allWeekDays: WeekDayCode[];
}

export class Supply implements SupplyModel {
    mainService: ServiceModel;
    services: ServiceModel[];
    products: string[];
    workingDays: WorkingDaysModel[];

    location: LocationModel;
    languages: string[];
    options: SupplyOptions;

    allWorkDays: WorkDayCode[];
    allWeekDays: WeekDayCode[];

    constructor(
        mainService: ServiceModel,
        services: ServiceModel | ServiceModel[],
        products: string[],
        location: LocationModel,
        languages: string | string[],
        workingDays: WorkingDaysModel | WorkingDaysModel[],
        options: SupplyOptions,
    ) {
        this.mainService = mainService;
        this.services = asArray(services);
        this.products = asArray(products);
        this.workingDays = asArray(workingDays);

        this.languages = asArray(languages);
        this.location = location;
        this.options = options;

        this.allWeekDays = this.workingDays
            .map((wDay) => asArray(wDay.fullList))
            .reduce((result = [], weekDays) => {
                return [...result, ...asArray(weekDays)];
            }, []);

        this.allWorkDays = this.workingDays
            .map((wDay) => asArray(wDay.workDays))
            .reduce((result = [], workDays) => {
                return [...result, ...asArray(workDays)];
            }, []);
    }

    get hasContactData(): boolean {
        return this.location?.hasContactData === true;
    }

    get hasAddressData(): boolean {
        return !!this.location?.address;
    }

    get address(): AddressModel {
        return this.location?.address ?? {};
    }

    get contact(): ContactModel {
        return this.location?.contact ?? {};
    }

    get town(): string {
        return this.location?.town ?? '';
    }

    get departureDays(): WorkingDaysModel[] {
        return this.workingDays.filter((workingDay) => isNotEmpty(workingDay.departureLocations));
    }

    get returnDays(): WorkingDaysModel[] {
        return this.workingDays.filter((workingDay) => isNotEmpty(workingDay.returnLocations));
    }

    get departureLocations(): LocationModel[] {
        return asArray(this.workingDays)
            .map((workingDay) => workingDay.departureLocations)
            .reduce((collector = [], locations = []) => [...collector, ...locations], []) ?? [];
    }

    get returnLocations(): LocationModel[] {
        return asArray(this.workingDays)
            .map((workingDay) => workingDay.returnLocations)
            .reduce((collector = [], locations = []) => [...collector, ...locations], []) ?? [];
    }
}
