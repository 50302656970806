export enum CategoryServiceType {
	BABYSITTING = 'babysitting',
	CLEANING = 'cleaning',

	ACCOUNTANT = 'accountant',
	ADVERTISING = 'advertising',
	CONSULTING = 'consulting',
	FINANCE = 'finance',
	INSURANCE = 'insurance',
	JURIDICAL = 'juridical',
	LAWYER = 'lawyer',
	NOTARY = 'notary',
	TRANSLATION = 'translation',

	BUILDING = 'building',
	COMPUTER = 'computer',
	ELECTRONIC = 'electronic',
	MECHANIC = 'mechanic',

	ACTIVITIES = 'activities',
	COURSES = 'courses',
	SCHOOLS = 'schools',
	WORKSHOPS = 'workshops',

	CATERING = 'catering',
	DECOR = 'decor',
	EATING = 'eating',
	EVENTS = 'events',
	MUSIC = 'music',
	PHOTO_VIDEO = 'photovideo',

	BEAUTY = 'beauty',
	CLINICS = 'clinics',
	DOCTORS = 'doctors',
	SPA = 'spa',
	ZEN = 'zen',

	PACKAGES = 'packages',
	PASSENGERS = 'passengers',

	ARTS = 'arts',
	GIFTS = 'gifts',
	HANDMADE = 'handmade',

	CLUBS = 'clubs',
	RECREATION = 'recreation',

	MARKETS = 'markets',
	SHOPS = 'shops',

	ESTATE = 'estate',
	JOBS = 'jobs',

	CHURCH = 'church',

	// -- commons
	PRODUCTS = 'products',
	VARIOUS = 'various'
}

export enum ConveyanceType {
	BUS = 'bus',
	MINIBUS = 'minibus'
}

export enum CoveringType {
	LOCAL = 'local',
	INTERNATIONAL = 'international'
}

export enum SubjectType {
	P = 'P',
	E = 'E'
}

export const enumValues = {};

// export const makeEnum = (translatePrefix, ...values) => {
//     if (isStringNotEmpty(translatePrefix) && isNotEmpty(values)) {
//         const enumObject = values.filter(isStringNotEmpty)
//             // .map(upperCaseIfString)
//             .reduce(
//                 (obj, value) => {
//                     const newSymbol = Symbol.for(value);
//                     enumValues[newSymbol] = `${translatePrefix}${value.toLowerCase()}`;
//                     return { ...obj, [value]: newSymbol };
//                 },
//                 {
//                     codeNameList: function () {
//                         const currentEnum = this;
//                         return Object.keys(currentEnum).filter(key => key !== 'codeNameList')
//                             .map(key => ({
//                                 id: currentEnum[key],
//                                 code: key,
//                                 name: enumValues[currentEnum[key]],
//                             }));
//                     },
//                 },
//             );
//         return Object.freeze(enumObject);
//     }
//     return null;
// };
//
// export const CATEGORY_TRANSPORT = makeEnum(
//     'category.transport.',
//     'passengers', 'airport', 'materials', 'packages', 'trailer', 'special',
// );
//
// export const CONVEYANCE = makeEnum(
//     'transport.conveyance.',
//     'bus', 'minibus',
// );
//
//
//
// export const COVERING = makeEnum(
//     'covering.',
//     'local', 'international',
// );
//
// export const SUBJECT = makeEnum(
//     'subject.type.',
//     'P', 'E',
// );
