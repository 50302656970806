import type { C9Map } from '$lib/models/object.models';
import { asArray, defaultEmpty } from '@rigu/js-toolkit';
import type { LocationModel } from './contact.models';
import { RoundTripType } from './contact.models';

export interface WeekDay {
    code: WeekDayCode;
    ranking: number;
}

export enum WeekDayCode {
    M = 'M',
    T = 'T',
    W = 'W',
    R = 'R',
    F = 'F',
    S = 'S',
    U = 'U'
}

export enum WorkDayCode {
    M = 'M',
    T = 'T',
    W = 'W',
    R = 'R',
    F = 'F',
    S = 'S',
    U = 'U',
    MF = 'MF'
}

export const WORK_DAY_SELECT_OPTIONS = [
    WorkDayCode.MF,
    WorkDayCode.M,
    WorkDayCode.T,
    WorkDayCode.W,
    WorkDayCode.R,
    WorkDayCode.F,
    WorkDayCode.S,
    WorkDayCode.U,
].map((code) => ({code: code, name: 'workingDay.' + code}));

export const WEEK_DAY_SELECT_OPTIONS = [
    WeekDayCode.M,
    WeekDayCode.T,
    WeekDayCode.W,
    WeekDayCode.R,
    WeekDayCode.F,
    WeekDayCode.S,
    WeekDayCode.U,
].map((code) => ({code: code, name: 'weekDay.' + code}));

export const WEEK_DAY_CODE_MAPPING: C9Map<WorkDayCode> = {
    LU: WorkDayCode.M,
    MA: WorkDayCode.T,
    MI: WorkDayCode.W,
    JO: WorkDayCode.R,
    VI: WorkDayCode.F,
    SA: WorkDayCode.S,
    DU: WorkDayCode.U,
};

export const WORK_DAY_CODE_MAPPING: C9Map<WorkDayCode> = {
    LU: WorkDayCode.M,
    MA: WorkDayCode.T,
    MI: WorkDayCode.W,
    JO: WorkDayCode.R,
    VI: WorkDayCode.F,
    SA: WorkDayCode.S,
    DU: WorkDayCode.U,
    LUVI: WorkDayCode.MF,
};

export const WEEK_DAYS: WeekDay[] = [
    {code: WeekDayCode.M, ranking: 1},
    {code: WeekDayCode.T, ranking: 2},
    {code: WeekDayCode.W, ranking: 3},
    {code: WeekDayCode.R, ranking: 4},
    {code: WeekDayCode.F, ranking: 5},
    {code: WeekDayCode.S, ranking: 6},
    {code: WeekDayCode.U, ranking: 7},
];

export const parseWorkingDay = (strValue: string): WorkDayCode => {
    const dayCode = defaultEmpty(strValue).replace(/\s+/g, '').replace('-', '');
    return WorkDayCode[dayCode as keyof typeof WorkDayCode] || WORK_DAY_CODE_MAPPING[dayCode];
};

export interface WorkingDaysModel {
    workDays?: WorkDayCode[];
    fullList?: WeekDayCode[];
    target?: LocationModel[];

    departureLocations: LocationModel[];
    returnLocations: LocationModel[];
    roundTrip: RoundTripType;
}

export class WorkingDays implements WorkingDaysModel {
    workDays: WorkDayCode[];
    fullList: WeekDayCode[];
    target: LocationModel[];

    constructor(
        workDayCodes: WorkDayCode[] = [],
        fullList: WeekDayCode[] = [],
        target: LocationModel | LocationModel[] = {},
    ) {
        this.workDays = asArray(workDayCodes);
        this.fullList = asArray(fullList);
        this.target = asArray(target);
    }

    get departureLocations(): LocationModel[] {
        return this.target.filter((location) => {
            return location.roundTrip === RoundTripType.DEPARTURE;
        });
    }

    get returnLocations(): LocationModel[] {
        return this.target.filter((location) => location.roundTrip === RoundTripType.RETURN);
    }

    get roundTrip(): RoundTripType {
        const location = this.target.length > 0 ? this.target[0] : null;
        return <RoundTripType>location?.roundTrip;
    }
}
