import { asArray } from '@rigu/js-toolkit';
import { serviceTypeGroupKey, serviceTypeKey } from '$lib/toolkit/factory.utils';
import type { TokenizedModel } from './common.models';
import { Tokenized } from './common.models';

export const SERVICE_GROUP_TYPES = [
    'home',
    'admin',
    'improve',
    'educational',
    'entertainment',
    'health',
    'transport',
    'arts_craft',
    'sports',
    'commerce',
    'agencies',
    'churches',
];

export interface ServiceCategoryModel extends TokenizedModel {
    name: string;
    group?: string;
    type?: string;
}

export class ServiceCategory extends Tokenized implements ServiceCategoryModel {
    name: string;
    group: string;
    type: string;

    constructor(name: string, group: string, tokens: string[] | undefined) {
        super(tokens);
        const serviceTypeName = serviceTypeKey(group, name);
        this.name = serviceTypeName;
        this.group = group;
        this.type = serviceTypeName;
    }
}

export interface CategoryGroupModel {
    name: string;
    type: string;
    categories: ServiceCategoryModel[];
    lastTokenExists?: boolean;
    single?: boolean;

    expanded?: boolean;
}

export class CategoryGroup implements CategoryGroupModel {
    name: string;
    type: string;
    categories: ServiceCategory[];
    lastTokenExists: boolean = false;
    single: boolean = true;
    expanded: boolean = false;

    constructor(group: string, categories: ServiceCategory[] = [], single: boolean = false) {
        this.name = serviceTypeGroupKey(group);
        this.type = group;
        this.categories = asArray(categories);
        this.single = single;
    }

    resetLastTokenExists() {
        this.lastTokenExists = false;
        this.categories.forEach((category) => category.resetLastTokenExists());
    }

    hasToken(tokenToSearch: string | string[], persistTokenExist: boolean = true): boolean {
        const toSearch = asArray(tokenToSearch).map((token: string) => token.toLowerCase());
        const result = !!this.categories.find(
            (category) => category.hasToken(toSearch, persistTokenExist, true),
        );
        this.lastTokenExists = persistTokenExist && result;
        return result;
    }
}
