// import FaUserMd from 'svelte-icons/fa/FaUserMd.svelte';

import C9ActivitatiExtracuricularePin from '$lib/components/icons/pin-icons/C9ActivitatiExtracuricularePin.svelte';
import C9AsigurariPin from '$lib/components/icons/pin-icons/C9AsigurariPin.svelte';
import C9AtelierePin from '$lib/components/icons/pin-icons/C9AtelierePin.svelte';
import C9AvocatPin from '$lib/components/icons/pin-icons/C9AvocatPin.svelte';
import C9BabysittingPin from '$lib/components/icons/pin-icons/C9BabysittingPin.svelte';
import C9CadouriPin from '$lib/components/icons/pin-icons/C9CadouriPin.svelte';
import C9CateringPin from '$lib/components/icons/pin-icons/C9CateringPin.svelte';
import C9CentreAgrementPin from '$lib/components/icons/pin-icons/C9CentreAgrementPin.svelte';
import C9ConstructiiPin from '$lib/components/icons/pin-icons/C9ConstructiiPin.svelte';
import C9ContabilitatePin from '$lib/components/icons/pin-icons/C9ContabilitatePin.svelte';
import C9CreatiePin from '$lib/components/icons/pin-icons/C9CreatiePin.svelte';
import C9CurateniePin from '$lib/components/icons/pin-icons/C9CurateniePin.svelte';
import C9CursuriPin from '$lib/components/icons/pin-icons/C9CursuriPin.svelte';
import C9DecorPin from '$lib/components/icons/pin-icons/C9DecorPin.svelte';
import C9ElectroEchipamentePin from '$lib/components/icons/pin-icons/C9ElectroEchipamentePin.svelte';
import C9ElectroniceCalculatoarePin from '$lib/components/icons/pin-icons/C9ElectroniceCalculatoarePin.svelte';
import C9EvenimenteDiversePin from '$lib/components/icons/pin-icons/C9EvenimenteDiversePin.svelte';
import C9FinantePin from '$lib/components/icons/pin-icons/C9FinantePin.svelte';
import C9FotoVideoPin from '$lib/components/icons/pin-icons/C9FotoVideoPin.svelte';
import C9HandMadePin from '$lib/components/icons/pin-icons/C9HandMadePin.svelte';
import C9ImobiliarPin from '$lib/components/icons/pin-icons/C9ImobiliarPin.svelte';
import C9InfrumusetarePin from '$lib/components/icons/pin-icons/C9InfrumusetarePin.svelte';
import C9InstalariAutoPin from '$lib/components/icons/pin-icons/C9InstalariAutoPin.svelte';
import C9InstalariDiversePin from '$lib/components/icons/pin-icons/C9InstalariDiversePin.svelte';
import C9LocaluriPin from '$lib/components/icons/pin-icons/C9LocaluriPin.svelte';
import C9LocuriDeMuncaPin from '$lib/components/icons/pin-icons/C9LocuriDeMuncaPin.svelte';
import C9MagazinePin from '$lib/components/icons/pin-icons/C9MagazinePin.svelte';
import C9MuzicaPin from '$lib/components/icons/pin-icons/C9MuzicaPin.svelte';
import C9NotarPin from '$lib/components/icons/pin-icons/C9NotarPin.svelte';
import C9OrganizareEvenimentePin from '$lib/components/icons/pin-icons/C9OrganizareEvenimentePin.svelte';
import C9PietePin from '$lib/components/icons/pin-icons/C9PietePin.svelte';
import C9ProdusePin from '$lib/components/icons/pin-icons/C9ProdusePin.svelte';
import C9PRsiPublicitatePin from '$lib/components/icons/pin-icons/C9PRsiPublicitatePin.svelte';
import C9SanatateDiversePin from '$lib/components/icons/pin-icons/C9SanatateDiversePin.svelte';
import C9ScoliPin from '$lib/components/icons/pin-icons/C9ScoliPin.svelte';
import C9ServiciiJuridicePin from '$lib/components/icons/pin-icons/C9ServiciiJuridicePin.svelte';
import C9SpaPin from '$lib/components/icons/pin-icons/C9SpaPin.svelte';
import C9SpitalePin from '$lib/components/icons/pin-icons/C9SpitalePin.svelte';
import C9SportCluburiPin from '$lib/components/icons/pin-icons/C9SportCluburiPin.svelte';
import C9SportDiversePin from '$lib/components/icons/pin-icons/C9SportDiversePin.svelte';
import C9TraduceriPin from '$lib/components/icons/pin-icons/C9TraduceriPin.svelte';
import C9TransportColetePin from '$lib/components/icons/pin-icons/C9TransportColetePin.svelte';
import C9TransportDiversePin from '$lib/components/icons/pin-icons/C9TransportDiversePin.svelte';
import C9TransportPasageriPin from '$lib/components/icons/pin-icons/C9TransportPasageriPin.svelte';
import C9ZenPin from '$lib/components/icons/pin-icons/C9ZenPin.svelte';
import C9AgentiiJob from '$lib/components/icons/service-icons/C9AgentiiJob.svelte';
import C9ArtsCraft from '$lib/components/icons/service-icons/C9ArtsCraft.svelte';
import C9Asigurari from '$lib/components/icons/service-icons/C9Asigurari.svelte';
import C9Ateliere from '$lib/components/icons/service-icons/C9Ateliere.svelte';
import C9Ateliere4 from '$lib/components/icons/service-icons/C9Ateliere4.svelte';
import C9AutoMotoVelo from '$lib/components/icons/service-icons/C9AutoMotoVelo.svelte';
import C9Avocat from '$lib/components/icons/service-icons/C9Avocat.svelte';

import C9BabySitting from '$lib/components/icons/service-icons/C9BabySitting.svelte';
import C9Bullhorn from '$lib/components/icons/service-icons/C9Bullhorn.svelte';

import C9Cadouri from '$lib/components/icons/service-icons/C9Cadouri.svelte';
import C9Catering from '$lib/components/icons/service-icons/C9Catering.svelte';
import C9CentreAgrement from '$lib/components/icons/service-icons/C9CentreAgrement.svelte';
import C9CliniciSiSpitale from '$lib/components/icons/service-icons/C9CliniciSiSpitale.svelte';
import C9CluburiSportive from '$lib/components/icons/service-icons/C9CluburiSportive.svelte';
import C9CommerceCategory from '$lib/components/icons/service-icons/C9CommerceCategory.svelte';
import C9Constructii from '$lib/components/icons/service-icons/C9Constructii.svelte';
import C9Contabilitate from '$lib/components/icons/service-icons/C9Contabilitate.svelte';
import C9CreativeTools from '$lib/components/icons/service-icons/C9CreativeTools.svelte';
import C9Curatenie from '$lib/components/icons/service-icons/C9Curatenie.svelte';
import C9Cursuri from '$lib/components/icons/service-icons/C9Cursuri.svelte';

import C9DecorEvenimente from '$lib/components/icons/service-icons/C9DecorEvenimente.svelte';
import C9DiverseEvenimente from '$lib/components/icons/service-icons/C9DiverseEvenimente.svelte';
import C9DiverseInstlariSiReparatii from '$lib/components/icons/service-icons/C9DiverseInstlariSiReparatii.svelte';
import C9EducatieCultura from '$lib/components/icons/service-icons/C9EducatieCultura.svelte';
import C9ElectrocasniceSiEchipamente from '$lib/components/icons/service-icons/C9ElectrocasniceSiEchipamente.svelte';

import C9ElectroniceSiCalculatoare from '$lib/components/icons/service-icons/C9ElectroniceSiCalculatoare.svelte';
import C9Entertainment from '$lib/components/icons/service-icons/C9Entertainment.svelte';

import C9Finante from '$lib/components/icons/service-icons/C9Finante.svelte';
import C9Flower from '$lib/components/icons/service-icons/C9Flower.svelte';
import C9FotoVideo from '$lib/components/icons/service-icons/C9FotoVideo.svelte';
import C9HandMade from '$lib/components/icons/service-icons/C9HandMade.svelte';
import C9Health from '$lib/components/icons/service-icons/C9Health.svelte';

import C9Imobiliar from '$lib/components/icons/service-icons/C9Imobiliar.svelte';
import C9IosPin from '$lib/components/icons/service-icons/C9IosPin.svelte';

import C9Localuri from '$lib/components/icons/service-icons/C9Localuri.svelte';

import C9Magazine from '$lib/components/icons/service-icons/C9Magazine.svelte';
import C9Muzica from '$lib/components/icons/service-icons/C9Muzica.svelte';

import C9Notar from '$lib/components/icons/service-icons/C9Notar.svelte';

import C9OrganizareEvenimente from '$lib/components/icons/service-icons/C9OrganizareEvenimente.svelte';

import C9Piete1 from '$lib/components/icons/service-icons/C9Piete1.svelte';
import C9Pray from '$lib/components/icons/service-icons/C9Pray.svelte';
import C9ProceduriBeauty from '$lib/components/icons/service-icons/C9ProceduriBeauty.svelte';
import C9ProduseMagazineSiProduse from '$lib/components/icons/service-icons/C9ProduseMagazineSiProduse.svelte';
import C9ProduseSanateteSiIngrijire from '$lib/components/icons/service-icons/C9ProduseSanateteSiIngrijire.svelte';

import C9Scoli from '$lib/components/icons/service-icons/C9Scoli.svelte';
import C9ServiciiJuridice from '$lib/components/icons/service-icons/C9ServiciiJuridice.svelte';
import C9Spa from '$lib/components/icons/service-icons/C9Spa.svelte';
import C9Sport from '$lib/components/icons/service-icons/C9Sport.svelte';
import C9SportCategory from '$lib/components/icons/service-icons/C9SportCategory.svelte';

import C9Tools from '$lib/components/icons/service-icons/C9Tools.svelte';
import C9Traduceri from '$lib/components/icons/service-icons/C9Traduceri.svelte';
import C9Transport from '$lib/components/icons/service-icons/C9Transport.svelte';
import C9TransportColete from '$lib/components/icons/service-icons/C9TransportColete.svelte';
import C9TransportDiverse2 from '$lib/components/icons/service-icons/C9TransportDiverse2.svelte';
import C9TransportPasageri from '$lib/components/icons/service-icons/C9TransportPasageri.svelte';
import C9UserMd from '$lib/components/icons/service-icons/C9UserMd.svelte';

import C9YingYang from '$lib/components/icons/service-icons/C9YingYang.svelte';
import type { CategoryGroup } from '$lib/models/category.models';
import type { C9Map } from '$lib/models/object.models';
import type { ComponentType } from 'svelte';

export const SERVICE_ICONS: C9Map<ComponentType> = {
    'service.home.cleaning': C9Curatenie,
    'service.home.babysitting': C9BabySitting,

    'service.admin.accountant': C9Contabilitate,
    'service.admin.advertising': C9Bullhorn,
    'service.admin.consulting': C9ServiciiJuridice,
    'service.admin.finance': C9Finante,
    'service.admin.insurance': C9Asigurari,
    'service.admin.juridical': C9ServiciiJuridice,
    'service.admin.lawyer': C9Avocat,
    'service.admin.notary': C9Notar,
    'service.admin.translation': C9Traduceri,

    'service.improve.mechanic': C9AutoMotoVelo,
    'service.improve.building': C9Constructii,
    'service.improve.electronic': C9ElectrocasniceSiEchipamente,
    'service.improve.computer': C9ElectroniceSiCalculatoare,
    'service.improve.various': C9DiverseInstlariSiReparatii,

    'service.educational.activities': C9Ateliere4,
    'service.educational.courses': C9Cursuri,
    'service.educational.schools': C9Scoli,
    'service.educational.workshops': C9Ateliere,

    'service.entertainment.events': C9OrganizareEvenimente,
    'service.entertainment.eating': C9Localuri,
    'service.entertainment.catering': C9Catering,
    'service.entertainment.music': C9Muzica,
    'service.entertainment.decor': C9DecorEvenimente,
    'service.entertainment.photovideo': C9FotoVideo,
    'service.entertainment.various': C9DiverseEvenimente,

    'service.health.doctors': C9UserMd,
    'service.health.clinics': C9CliniciSiSpitale,
    'service.health.spa': C9Spa,
    'service.health.beauty': C9ProceduriBeauty,
    'service.health.products': C9ProduseSanateteSiIngrijire,
    'service.health.various': C9Flower,
    'service.health.zen': C9YingYang,

    'service.transport.packages': C9TransportColete,
    'service.transport.passengers': C9TransportPasageri,
    'service.transport.various': C9TransportDiverse2,

    'service.arts_craft.arts': C9CreativeTools,
    'service.arts_craft.handmade': C9HandMade,
    'service.arts_craft.gifts': C9Cadouri,
    'service.arts_craft.various': C9Tools,

    'service.sports.clubs': C9CluburiSportive,
    'service.sports.recreation': C9CentreAgrement,
    'service.sports.various': C9Sport,

    'service.commerce.shops': C9Magazine,
    'service.commerce.markets': C9Piete1,
    'service.commerce.products': C9ProduseMagazineSiProduse,

    'service.agencies.jobs': C9AgentiiJob,
    'service.agencies.estate': C9Imobiliar,

    'service.churches.church': C9Pray,
};

export const SERVICE_PIN_ICONS: C9Map<ComponentType> = {
    'service.home.cleaning': C9CurateniePin,
    'service.home.babysitting': C9BabysittingPin,

    'service.admin.accountant': C9ContabilitatePin,
    'service.admin.advertising': C9PRsiPublicitatePin,
    'service.admin.consulting': C9ServiciiJuridicePin,
    'service.admin.finance': C9FinantePin,
    'service.admin.insurance': C9AsigurariPin,
    'service.admin.juridical': C9ServiciiJuridicePin,
    'service.admin.lawyer': C9AvocatPin,
    'service.admin.notary': C9NotarPin,
    'service.admin.translation': C9TraduceriPin,

    'service.improve.mechanic': C9InstalariAutoPin,
    'service.improve.building': C9ConstructiiPin,
    'service.improve.electronic': C9ElectroEchipamentePin,
    'service.improve.computer': C9ElectroniceCalculatoarePin,
    'service.improve.various': C9InstalariDiversePin,

    'service.educational.activities': C9ActivitatiExtracuricularePin,
    'service.educational.courses': C9CursuriPin,
    'service.educational.schools': C9ScoliPin,
    'service.educational.workshops': C9AtelierePin,

    'service.entertainment.events': C9OrganizareEvenimentePin,
    'service.entertainment.eating': C9LocaluriPin,
    'service.entertainment.catering': C9CateringPin,
    'service.entertainment.music': C9MuzicaPin,
    'service.entertainment.decor': C9DecorPin,
    'service.entertainment.photovideo': C9FotoVideoPin,
    'service.entertainment.various': C9EvenimenteDiversePin,

    'service.health.doctors': C9SanatateDiversePin,
    'service.health.clinics': C9SpitalePin,
    'service.health.spa': C9SpaPin,
    'service.health.beauty': C9InfrumusetarePin,
    'service.health.products': C9ProdusePin,
    'service.health.various': C9SanatateDiversePin,
    'service.health.zen': C9ZenPin,

    'service.transport.packages': C9TransportColetePin,
    'service.transport.passengers': C9TransportPasageriPin,
    'service.transport.various': C9TransportDiversePin,

    'service.arts_craft.arts': C9CreatiePin,
    'service.arts_craft.handmade': C9HandMadePin,
    'service.arts_craft.gifts': C9CadouriPin,
    'service.arts_craft.various': C9CreatiePin,

    'service.sports.clubs': C9SportCluburiPin,
    'service.sports.recreation': C9CentreAgrementPin,
    'service.sports.various': C9SportDiversePin,

    'service.commerce.shops': C9MagazinePin,
    'service.commerce.markets': C9PietePin,
    'service.commerce.products': C9ProdusePin,

    'service.agencies.jobs': C9LocuriDeMuncaPin,
    'service.agencies.estate': C9ImobiliarPin,

    'service.churches.church': C9IosPin,
};
export const SERVICE_GROUP_ICONS = {
    home: C9Curatenie,
    admin: C9Contabilitate,
    improve: C9AutoMotoVelo,
    educational: C9EducatieCultura,
    entertainment: C9Entertainment,
    health: C9Health,
    transport: C9Transport,
    arts_craft: C9ArtsCraft,
    sports: C9SportCategory,
    commerce: C9CommerceCategory,
    agencies: C9Imobiliar,
    churches: C9Pray,
};

export const SERVICES_CATEGORIES: C9Map<CategoryGroup> = {};
