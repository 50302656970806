import { SERVICES_CATEGORIES } from '$lib/config/config-service-list';
import { serviceTypeGroupKey, serviceTypeKey } from '$lib/toolkit/factory.utils';
import { asArray, defaultEmpty, isDefined, isString } from '@rigu/js-toolkit';
import type { CategoryGroupModel, ServiceCategoryModel } from './category.models';
import { CategoryGroup, ServiceCategory } from './category.models';
import type { AddressModel, ContactModel, LocationModel, PhoneModel } from './contact.models';
import { Address, Contact, EntityLocation, Phone, RoundTripType } from './contact.models';
import type { EntityModel, ExecutiveModel, NameAliasModel } from './entity.models';
import { Entity, ExecutiveEntity, NameAlias, SkillModel } from './entity.models';
import { SubjectType } from './enum.models';
import { ServiceModel, Supply, type SupplyModel, type SupplyOptions } from './service.models';
import type { WorkingDaysModel } from './week.models';
import { parseWorkingDay, WEEK_DAY_CODE_MAPPING, WEEK_DAYS, WeekDayCode, WorkingDays } from './week.models';

export const buildModel = <T>(payload: T | undefined, factory: (obj: T) => T): T | undefined => {
    return payload ? factory.call(undefined, payload) : undefined;
};

export class CategoryFactory {
    static serviceCategory(category: Partial<ServiceCategoryModel>): ServiceCategoryModel {
        const {name, group, tokens} = category;
        return new ServiceCategory(name ?? '', group ?? '', tokens);
    }

    static categoryGroup(type: string): CategoryGroupModel {
        return new CategoryGroup(type);
    }

    static group(categoryGroup: Partial<CategoryGroupModel>): void {
        const deepCategories = asArray(categoryGroup.categories).map((category) =>
            buildModel({...category, group: categoryGroup.type}, CategoryFactory.serviceCategory),
        ) as ServiceCategory[];

        const categoryGroupType: string = categoryGroup.type as string;

        SERVICES_CATEGORIES[categoryGroupType] = new CategoryGroup(
            categoryGroupType,
            deepCategories,
            categoryGroup.single,
        );
    }
}

export class EntityFactory {
    static phone({dialCode, phone}: PhoneModel): PhoneModel {
        return new Phone(dialCode, phone);
    }

    static address({country, town, street, streetNo, postcode, box}: AddressModel = {}): AddressModel {
        return new Address(country, town, street, streetNo, postcode, box);
    }

    static contact({phones, emails, facebook, web}: ContactModel = {}): ContactModel {
        const deepPhones = asArray(phones)
            .filter(isDefined)
            .map((phone) =>
                isString(phone) ? new Phone('', phone as unknown as string) : EntityFactory.phone(phone),
            );
        return new Contact(deepPhones, emails ?? [], facebook ?? [], web ?? []);
    }

    static nameAlias({name, middle, surname, wholeName}: NameAliasModel): NameAliasModel {
        return new NameAlias(name, middle, surname, wholeName);
    }

    static workingDays({workDays, fullList, target}: Partial<WorkingDaysModel> = {}): WorkingDaysModel {
        return new WorkingDays(
            workDays,
            fullList,
            asArray(target).map((location) => buildModel(location, EntityFactory.entityLocation) as LocationModel),
        );
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    static workingDaysFromString({timetable, target}): WorkingDaysType {
        const fullList: WeekDayCode[] = [];
        const workingDays = defaultEmpty(timetable)
            .toUpperCase()
            .split(',')
            .map((strValue) => {
                const weekDays =
                    strValue
                        .split('-')
                        .map((strCode) => {
                            const code = strCode.trim();
                            const weekDayCode =
                                code in WeekDayCode
                                    ? WeekDayCode[code as keyof typeof WeekDayCode]
                                    : WEEK_DAY_CODE_MAPPING[code];
                            return weekDayCode ? WEEK_DAYS.find((day) => day.code === weekDayCode) : null;
                        })
                        .filter(isDefined)
                        .sort((a, b) => a?.ranking ?? 0 - (b?.ranking ?? 0)) ?? [];

                if (weekDays.length > 1) {
                    WEEK_DAYS.forEach((day) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        if (day.ranking >= weekDays[0]?.ranking && day.ranking <= weekDays[1]?.ranking) {
                            fullList.push(day.code);
                        }
                    });
                }
                else if (weekDays.length === 1) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    fullList.push(weekDays[0].code);
                }
                return parseWorkingDay(strValue);
            });

        return new WorkingDays(workingDays, fullList, target);
    }

    static entityLocation({
        address,
        contact,
        workingDays,
        roundTrip,
        timetable,
    }: LocationModel = {}): LocationModel {
        return new EntityLocation(
            buildModel(address as AddressModel, EntityFactory.address) as AddressModel,
            buildModel(contact as ContactModel, EntityFactory.contact) as ContactModel,
            buildModel(workingDays as WorkingDays, EntityFactory.workingDays) as WorkingDays,
            roundTrip as RoundTripType,
            timetable as string [],
        );
    }

    static skill({domain}: SkillModel): SkillModel {
        return new SkillModel(domain);
    }

    static executive({nameAlias, contact}: ExecutiveModel = {}): ExecutiveModel {
        return new ExecutiveEntity(
            buildModel(nameAlias ?? {}, EntityFactory.nameAlias) as NameAliasModel,
            buildModel(contact ?? {}, EntityFactory.contact) as ContactModel,
        );
    }

    static service({title, description}: ServiceModel): ServiceModel {
        return new ServiceModel(title, description);
    }

    static supply({
        mainService,
        services,
        products,
        location,
        languages,
        workingDays,
        options,
    }: SupplyModel): SupplyModel {
        return new Supply(
            buildModel(mainService, EntityFactory.service) as ServiceModel,
            asArray(services).map((service) => buildModel(service, EntityFactory.service) as ServiceModel),
            products,
            buildModel(location, EntityFactory.entityLocation) as LocationModel,
            languages,
            asArray(workingDays).map((workingDay) => buildModel(workingDay, EntityFactory.workingDays) as WorkingDays),
            buildModel(options, EntityFactory.supplyOptions) as SupplyOptions,
        );
    }

    static supplyOptions(objOptions: SupplyOptions): SupplyOptions {
        return objOptions as SupplyOptions;
    }

    static entity({
        uuid,
        serviceGroup,
        serviceTypes,
        nameAlias,
        subject = SubjectType.P,
        contact,
        address,
        skills = [],
        executive,
        supply,
        supplyOptions,
        languages,
        logo,
        ranking,
    }: EntityModel): Entity {
        return new Entity(
            uuid,
            serviceTypeGroupKey(serviceGroup),
            asArray(serviceTypes).map((serviceType) => serviceTypeKey(serviceGroup, serviceType)),
            buildModel(nameAlias, EntityFactory.nameAlias),
            subject as unknown as SubjectType,
            buildModel(contact, EntityFactory.contact),
            buildModel(address, EntityFactory.address),
            asArray(skills).map((skill) => buildModel(skill, EntityFactory.skill)) as SkillModel[],
            buildModel(executive, EntityFactory.executive),
            buildModel((supply as unknown as SupplyModel), EntityFactory.supply),
            buildModel(supplyOptions, EntityFactory.supplyOptions),
            languages,
            logo,
            ranking,
        );
    }
}
