<script lang="ts">
    import C9IconBase from "$lib/components/C9Icon/C9IconBase.svelte";
</script>

<C9IconBase {...$$props}>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 480.006 480.006" style="enable-background:new 0 0 480.006 480.006;" xml:space="preserve">
<g>
	<g>
		<path d="M472,380.002h-8.888c22.098-21.505,22.579-56.853,1.074-78.951c-3.412-3.506-7.27-6.548-11.474-9.049
			c16.9-10.092,27.26-28.316,27.288-48c-0.04-30.911-25.089-55.96-56-56h-72v-56c-0.002-4.418-3.585-7.999-8.003-7.997
			c-0.406,0-0.812,0.031-1.213,0.093L248,138.674v-7.472c18.611-3.827,31.976-20.199,32-39.2c0-34.912-32.936-68.256-34.344-69.656
			c-3.124-3.123-8.188-3.123-11.312,0C232.936,23.746,200,57.09,200,92.002c0.024,19.001,13.389,35.373,32,39.2v9.944l-97.216,14.96
			c-3.899,0.6-6.779,3.951-6.784,7.896v89.128c-5.843-6.302-12.582-11.71-20-16.048c-2.475-1.429-5.525-1.429-8,0
			c-23.624,13.727-39.572,37.608-43.2,64.688c-5.529-1.134-11.156-1.726-16.8-1.768c-3.531,0.016-7.057,0.245-10.56,0.688
			c-2.838,0.342-5.277,2.172-6.4,4.8c-16.302,38.24-3.597,82.671,30.456,106.512H0v16h19.056l13.784,27.576
			c1.355,2.712,4.128,4.425,7.16,4.424h304c4.418,0,8-3.582,8-8v-24h51.056l13.784,27.576c1.355,2.712,4.128,4.425,7.16,4.424h32
			c4.418,0,8-3.582,8-8v-24h8c4.418,0,8-3.582,8-8v-32C480,383.584,476.418,380.002,472,380.002z M464,340.002
			c-0.026,22.08-17.92,39.974-40,40H280c-22.091,0-40-17.909-40-40c0-22.091,17.909-40,40-40h104c13.255,0,24,10.745,24,24
			s-10.745,24-24,24h-80c-4.418,0-8-3.582-8-8s3.582-8,8-8h56v-16h-56c-13.255,0-24,10.745-24,24s10.745,24,24,24h80
			c22.08-0.026,39.974-17.92,40-40c-0.029-8.684-2.914-17.117-8.208-24H424C446.08,300.028,463.974,317.922,464,340.002z
			 M400,204.002c13.255,0,24,10.745,24,24s-10.745,24-24,24h-80c-4.418,0-8-3.582-8-8s3.582-8,8-8h56v-16h-56
			c-13.255,0-24,10.745-24,24s10.745,24,24,24h80c22.08-0.026,39.974-17.92,40-40c-0.008-8.252-2.592-16.295-7.392-23.008
			c21.544,4.888,35.047,26.315,30.159,47.859c-4.113,18.131-20.176,31.037-38.767,31.149h-40h-72c-22.091,0-40-17.909-40-40
			c0-22.091,17.909-40,40-40H400z M216,92.002c0-20.456,15.4-41.728,24-52c8.608,10.264,24,31.496,24,52
			c-0.042,10.134-6.445,19.15-16,22.528v-14.528h-16v14.528C222.445,111.152,216.042,102.136,216,92.002z M104,253.426
			c17.873,12.029,29.446,31.408,31.56,52.848c-11.932,4.765-22.678,12.08-31.488,21.432c-8.874-9.316-19.671-16.586-31.64-21.304
			C74.509,284.913,86.089,265.48,104,253.426z M144.13,320.072c7.67-2.694,15.74-4.07,23.87-4.07c1.256,0,2.568,0.048,3.936,0.136
			c13.18,37.517-6.549,78.614-44.066,91.794c-7.67,2.694-15.741,4.07-23.87,4.07c-1.256,0-2.568-0.048-3.936-0.136
			C86.884,374.349,106.613,333.252,144.13,320.072z M32,340.002c-0.01-8.128,1.364-16.198,4.064-23.864
			c1.368-0.088,2.68-0.136,3.936-0.136c20.716-0.062,40.433,8.894,54.016,24.536c-13.119,20.164-17.263,44.875-11.44,68.216
			C52.487,399.376,31.994,371.519,32,340.002z M163.056,444.002H44.944l-8-16h134.112L163.056,444.002z M240,444.002h-59.056l8-16
			H208v-16h-53.56c34.091-23.808,46.828-68.253,30.52-106.504c-1.123-2.628-3.562-4.458-6.4-4.8
			c-9.103-1.17-18.337-0.846-27.336,0.96c-1.248-8.898-3.857-17.551-7.736-25.656H144V170.866l192-29.536v46.672h-24
			c-30.888-0.041-55.961,24.966-56.002,55.854c-0.02,15.334,6.256,30.004,17.362,40.578c-30.667,3.618-52.593,31.412-48.975,62.079
			c1.501,12.724,7.327,24.547,16.503,33.489H232c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h8V444.002z M275.056,444.002H256v-16
			h27.056L275.056,444.002z M336,444.002h-43.056l8-16H336V444.002z M448,444.002h-19.056l-8-16H448V444.002z M464,412.002H240v-16
			h224V412.002z"/>
	</g>
</g>
<g>
	<g>
		<rect x="160" y="188.002" width="16" height="64"/>
	</g>
</g>
<g>
	<g>
		<rect x="160" y="268.002" width="16" height="16"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

</C9IconBase>
