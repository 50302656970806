<script lang="ts">
    import C9IconBase from "$lib/components/C9Icon/C9IconBase.svelte";
</script>

<C9IconBase {...$$props}>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512.002 512.002" style="enable-background:new 0 0 512.002 512.002;" xml:space="preserve">
<g>
	<g>
		<path d="M109.834,496.21l-15.055-21.206c-3.196-4.504-9.439-5.563-13.943-2.366c-4.503,3.197-5.563,9.439-2.365,13.943
			l15.055,21.207c1.95,2.747,5.032,4.212,8.163,4.212c2.002,0,4.023-0.6,5.78-1.847C111.972,506.956,113.032,500.714,109.834,496.21
			z"/>
	</g>
</g>
<g>
	<g>
		<path d="M225.206,499.946l-16.384-78.088c-7.967-37.972-30.087-70.806-62.283-92.455l-34.981-23.522
			c-14.027-9.43-32.881-7.402-44.605,4.553V189.938c0-15.816-10.783-29.254-26.223-32.681c-9.965-2.214-20.243,0.184-28.204,6.571
			c-7.96,6.387-12.525,15.904-12.525,26.11V344.99c0,20.313,6.216,39.806,17.975,56.371l17.833,25.121
			c1.95,2.747,5.032,4.212,8.163,4.212c2.002,0,4.023-0.6,5.78-1.847c4.503-3.197,5.563-9.439,2.365-13.943l-17.833-25.121
			c-9.344-13.163-14.283-28.652-14.283-44.794V189.938c0-4.108,1.838-7.939,5.042-10.511c3.203-2.571,7.34-3.536,11.354-2.645
			c6.215,1.379,10.556,6.789,10.556,13.156v123.016c0,17.414,6.398,34.005,18.021,46.913c0.498,0.597,1.01,1.188,1.551,1.761
			c6.641,7.037,14.449,15.329,21.803,23.145l10.295,10.957c0.033,0.035,0.069,0.065,0.103,0.099
			c8.807,9.367,15.302,16.284,15.438,16.428c3.779,4.025,10.109,4.225,14.135,0.444s4.225-10.109,0.443-14.135
			c-0.182-0.194-11.888-12.659-25.256-26.868l-22.929-24.403c-0.058-0.062-0.111-0.127-0.169-0.189
			c-5.376-6.43-5.309-15.753,0.326-22.115c4.985-5.626,13.449-6.706,19.686-2.513L135.379,346
			c27.848,18.725,46.979,47.123,53.869,79.965l16.385,78.089c0.989,4.713,5.146,7.948,9.777,7.948c0.68,0,1.371-0.07,2.063-0.215
			C222.878,510.653,226.34,505.351,225.206,499.946z"/>
	</g>
</g>
<g>
	<g>
		<path d="M74.089,445.858l-0.064-0.09c-3.21-4.496-9.454-5.54-13.949-2.332s-5.539,9.453-2.332,13.948l0.064,0.09
			c1.952,2.735,5.027,4.193,8.149,4.193c2.01,0,4.039-0.604,5.8-1.861C76.251,456.598,77.296,450.353,74.089,445.858z"/>
	</g>
</g>
<g>
	<g>
		<path d="M499.475,163.829c-7.96-6.387-18.243-8.781-28.203-6.571c-15.44,3.426-26.224,16.865-26.224,32.681v120.496
			c-11.723-11.955-30.579-13.983-44.605-4.553l-34.981,23.521c-32.197,21.648-54.316,54.483-62.283,92.455l-16.384,78.089
			c-1.134,5.405,2.328,10.706,7.733,11.84c0.692,0.145,1.384,0.215,2.063,0.215c4.632,0,8.788-3.236,9.777-7.949l16.384-78.089
			c6.891-32.841,26.021-61.24,53.869-79.964l34.981-23.521c6.235-4.194,14.699-3.114,19.686,2.512
			c5.628,6.354,5.703,15.662,0.347,22.092c-0.064,0.07-0.125,0.142-0.189,0.212c0,0-48.005,51.079-48.186,51.271
			c-3.781,4.026-3.582,10.354,0.443,14.135c4.026,3.781,10.354,3.582,14.135-0.444c0.136-0.144,40.998-43.591,47.639-50.628
			c0.541-0.573,1.053-1.164,1.551-1.761c11.622-12.908,18.021-29.499,18.021-46.913V189.938c0-6.367,4.341-11.777,10.557-13.156
			c4.013-0.89,8.149,0.075,11.354,2.645c3.204,2.571,5.042,6.402,5.042,10.511V344.99c0,16.142-4.939,31.631-14.283,44.794
			l-75.551,106.427c-3.197,4.503-2.138,10.746,2.365,13.943c1.758,1.247,3.778,1.847,5.78,1.847c3.13,0,6.213-1.466,8.163-4.212
			l75.551-106.427c11.759-16.565,17.975-36.058,17.975-56.371V189.938C511.999,179.732,507.435,170.216,499.475,163.829z"/>
	</g>
</g>
<g>
	<g>
		<path d="M382.658,191.66l-17.791-4.25v-3.19c11.563-8.1,19.145-21.513,19.145-36.671V124.42c0-24.679-20.077-44.756-44.756-44.756
			c-11.075,0-21.215,4.052-29.038,10.74C301.508,69.074,280.55,54,256.126,54c-24.454,0-45.434,15.113-54.124,36.484
			c-7.835-6.736-18.012-10.822-29.132-10.822c-24.679,0-44.756,20.077-44.756,44.756v1.537v21.592
			c0,15.158,7.582,28.571,19.145,36.671v3.19l-17.791,4.25c-21.242,5.075-36.078,23.872-36.078,45.712v18.676
			c0,5.523,4.478,10,10,10h45.952c-0.071,1.213-0.12,2.432-0.12,3.661v35.958c0,5.523,4.478,10,10,10h60.11c5.522,0,10-4.477,10-10
			s-4.478-10-10-10H209V275c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10v20.667h-19.777v-25.958
			c0-19.287,13.1-35.896,31.854-40.39l27.442-6.576l18.365,41.782c1.596,3.631,5.188,5.976,9.153,5.976h0.001
			c3.966,0,7.558-2.344,9.154-5.975l18.346-41.72l27.355,6.535c18.772,4.485,31.883,21.096,31.883,40.397v25.929h-19.777V275
			c0-5.523-4.478-10-10-10c-5.522,0-10,4.477-10,10v20.667h-11.333c-5.522,0-10,4.477-10,10s4.478,10,10,10h61.111
			c5.522,0,10-4.477,10-10v-35.929c0-1.239-0.05-2.467-0.122-3.69h46.082c5.522,0,10-4.477,10-10v-18.676
			C418.737,215.532,403.901,196.734,382.658,191.66z M339.256,99.662c13.102,0,23.858,10.231,24.702,23.123h-13.903
			c-11.688,0-23.19-2.814-33.529-8.172C320.329,105.825,329.087,99.662,339.256,99.662z M314.5,146.087
			c0.012-0.487,0.037-0.972,0.037-1.462v-8.906c11.221,4.644,23.288,7.066,35.518,7.066h13.957v4.763
			c0,13.65-11.105,24.756-24.756,24.756S314.5,161.198,314.5,147.547V146.087z M217.716,112.41L217.716,112.41
			c0-21.179,17.23-38.41,38.41-38.41c19.103,0,34.992,14.018,37.926,32.306l-16.835-9.891c-4.211-2.474-9.593-1.545-12.73,2.2
			c-8.227,9.818-20.292,15.449-33.102,15.449h-13.669V112.41z M172.871,99.662c10.169,0,18.926,6.164,22.73,14.95
			c-10.34,5.358-21.841,8.172-33.529,8.172h-13.903C149.013,109.893,159.77,99.662,172.871,99.662z M148.115,142.784h13.957
			c12.243,0,24.323-2.427,35.555-7.081v11.843c0,13.65-11.105,24.756-24.756,24.756s-24.756-11.104-24.756-24.755V142.784z
			 M196.417,209.869c-19.674,4.714-35.136,18.431-42.495,36.178h-40.532v-8.676c0-12.546,8.522-23.345,20.725-26.26l25.468-6.084
			c4.501-1.075,7.677-5.098,7.677-9.726v-3.362c1.84,0.231,3.71,0.364,5.611,0.364c1.887,0,3.744-0.131,5.57-0.359v2.853
			c-0.007,0.149-0.01,0.298-0.011,0.447c-0.002,3.281,1.611,6.495,4.573,8.411c1.116,0.723,2.322,1.193,3.553,1.428l14.917,3.574
			L196.417,209.869z M224.311,194.345c-2.569-1.133-5.247-2.039-8.007-2.7l-17.864-4.281v-3.116
			c3.224-2.253,6.139-4.916,8.667-7.918c4.466,6.88,10.334,12.763,17.204,17.244V194.345z M267.743,209.038l-11.702,26.615
			l-11.729-26.683v-7.136c3.817,0.787,7.768,1.202,11.814,1.202c3.977,0,7.861-0.403,11.617-1.164V209.038z M256.126,183.035
			c-21.18,0.001-38.41-17.23-38.41-38.41v-10.562h13.669c15.858,0,30.936-5.897,42.492-16.415l20.622,12.116v16.324
			C293.727,166.591,276.816,183.035,256.126,183.035z M287.743,194.377V193.7c6.926-4.478,12.846-10.376,17.347-17.286
			c2.511,2.968,5.401,5.602,8.594,7.834v3.116l-17.865,4.281C293.035,192.313,290.334,193.23,287.743,194.377z M398.736,246.047
			h-40.67c-7.366-17.747-22.839-31.456-42.525-36.159l-5.021-1.199l14.997-3.594c1.248-0.232,2.474-0.708,3.605-1.44
			c2.957-1.913,4.565-5.127,4.565-8.408c0-0.078-0.001-0.157-0.003-0.235v-3.068c1.827,0.228,3.683,0.359,5.57,0.359
			c1.901,0,3.771-0.133,5.611-0.364v3.362c0,4.628,3.176,8.651,7.677,9.726l25.468,6.084c12.203,2.916,20.726,13.714,20.726,26.26
			V246.047z"/>
	</g>
</g>
<g>
	<g>
		<path d="M263.069,298.59c-1.869-1.86-4.439-2.92-7.069-2.92c-2.641,0-5.21,1.06-7.07,2.92c-1.87,1.87-2.93,4.44-2.93,7.08
			c0,2.63,1.06,5.2,2.93,7.07c1.86,1.86,4.43,2.93,7.07,2.93c2.63,0,5.2-1.07,7.069-2.93c1.86-1.87,2.931-4.44,2.931-7.07
			C266,303.03,264.93,300.46,263.069,298.59z"/>
	</g>
</g>
<g>
	<g>
		<path d="M255.993,0c-5.522,0-10,4.477-10,10v14.963c0,5.523,4.478,10,10,10c5.522,0,10-4.477,10-10V10
			C265.993,4.477,261.516,0,255.993,0z"/>
	</g>
</g>
<g>
	<g>
		<path d="M219.157,32.619l-10.49-10.48c-3.907-3.904-10.238-3.901-14.142,0.006c-3.904,3.907-3.9,10.239,0.007,14.142l10.491,10.48
			c1.952,1.951,4.51,2.926,7.067,2.926c2.561,0,5.121-0.978,7.074-2.932C223.066,42.854,223.064,36.522,219.157,32.619z"/>
	</g>
</g>
<g>
	<g>
		<path d="M317.222,22.395c-3.907-3.903-10.24-3.901-14.142,0.007l-10.409,10.419c-3.903,3.907-3.9,10.239,0.007,14.142
			c1.953,1.951,4.51,2.926,7.068,2.926c2.561,0,5.122-0.978,7.074-2.933l10.409-10.419C321.132,32.63,321.129,26.298,317.222,22.395
			z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

</C9IconBase>
