<script lang="ts">
    import C9IconBase from "$lib/components/C9Icon/C9IconBase.svelte";
</script>

<C9IconBase {...$$props}>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path d="M231.672,73.768h-31.86V41.907c0-4.199-3.405-7.604-7.604-7.604H156.33c-4.199,0-7.604,3.405-7.604,7.604v31.861h-31.86
			c-4.199,0-7.604,3.405-7.604,7.604v35.877c0,4.199,3.404,7.604,7.604,7.604h31.86v31.86c0,4.199,3.405,7.604,7.604,7.604h35.877
			c4.199,0,7.604-3.404,7.604-7.604v-31.86h31.86c4.199,0,7.604-3.405,7.604-7.604V81.372
			C239.276,77.173,235.871,73.768,231.672,73.768z M224.068,109.644h-31.86c-4.199,0-7.604,3.405-7.604,7.604v31.86h-20.669v-31.86
			c0-4.199-3.405-7.604-7.604-7.604h-31.86V88.976h31.86c4.199,0,7.604-3.405,7.604-7.604V49.511h20.669v31.861
			c0,4.199,3.404,7.604,7.604,7.604h31.86V109.644z"/>
	</g>
</g>
<g>
	<g>
		<path d="M504.396,59.388c4.199,0,7.604-3.405,7.604-7.604V16.572c0-4.199-3.405-7.604-7.604-7.604h-48.552
			c-4.199,0-7.604,3.405-7.604,7.604s3.405,7.604,7.604,7.604h40.948V44.18h-20.287H256.422
			c-5.014-7.447-11.013-14.176-17.807-20.003h181.744c4.199,0,7.604-3.404,7.604-7.604s-3.405-7.604-7.604-7.604H214.67
			c-12.343-5.542-26.018-8.629-40.401-8.629s-28.057,3.087-40.401,8.629H7.607c-4.199,0-7.604,3.405-7.604,7.604v35.211
			c0,4.199,3.405,7.604,7.604,7.604h20.287v265.953c0,4.199,3.405,7.604,7.604,7.604c4.199,0,7.604-3.404,7.604-7.604V59.388h40.616
			c-5.932,13.403-8.981,28.357-8.333,44.073c2.15,52.211,45.172,94.049,97.421,94.808c55.239,0.802,100.432-43.9,100.432-98.958
			c0-14.198-3.011-27.705-8.419-39.922h204.082V461.24h-17.62V342.239c0-4.199-3.405-7.604-7.604-7.604H312.781
			c-4.199,0-7.604,3.405-7.604,7.604V461.24H43.102V360.829c0-4.199-3.405-7.604-7.604-7.604c-4.199,0-7.604,3.404-7.604,7.604
			v100.412H7.604c-4.199,0-7.604,3.405-7.604,7.604v35.211c0,4.199,3.405,7.604,7.604,7.604h496.789
			c4.199,0,7.604-3.405,7.604-7.604v-35.211c0-4.199-3.405-7.604-7.604-7.604h-20.284V59.388H504.396z M35.498,44.18H15.211V24.176
			h94.711c-6.795,5.828-12.795,12.557-17.807,20.003H35.498z M174.269,183.072c-46.186,0-83.762-37.576-83.762-83.762
			c0-46.186,37.576-83.762,83.762-83.762S258.03,53.124,258.03,99.31C258.03,145.496,220.455,183.072,174.269,183.072z
			 M320.385,349.843h115.688v15.715H320.385V349.843z M436.074,380.764v80.475h-50.241v-80.475H436.074z M320.385,380.766h50.241
			v80.475h-50.241V380.766z M496.789,476.449v20.003H15.208v-20.003H496.789z"/>
	</g>
</g>
<g>
	<g>
		<path d="M362.193,213.739h-49.411c-4.199,0-7.604,3.405-7.604,7.604v90.228c0,4.199,3.405,7.604,7.604,7.604h49.411
			c4.199,0,7.604-3.405,7.604-7.604v-90.228C369.797,217.143,366.392,213.739,362.193,213.739z M354.589,303.967h-34.203V257.84
			h34.203V303.967z M354.589,242.633h-34.203v-13.686h34.203V242.633z"/>
	</g>
</g>
<g>
	<g>
		<path d="M199.223,213.739h-49.411c-4.199,0-7.604,3.405-7.604,7.604v90.228c0,4.199,3.404,7.604,7.604,7.604h49.411
			c4.199,0,7.604-3.405,7.604-7.604v-90.228C206.826,217.143,203.422,213.739,199.223,213.739z M191.619,303.967h-34.203V257.84
			h34.203V303.967z M191.619,242.633h-34.203v-13.686h34.203V242.633z"/>
	</g>
</g>
<g>
	<g>
		<path d="M443.677,213.739h-49.411c-4.199,0-7.604,3.405-7.604,7.604v90.228c0,4.199,3.405,7.604,7.604,7.604h49.411
			c4.199,0,7.604-3.405,7.604-7.604v-90.228C451.281,217.143,447.877,213.739,443.677,213.739z M401.87,228.947h34.202v13.686
			H401.87V228.947z M436.073,303.967H401.87V257.84h34.203V303.967z"/>
	</g>
</g>
<g>
	<g>
		<path d="M280.707,213.739h-49.411c-4.199,0-7.604,3.405-7.604,7.604v90.228c0,4.199,3.404,7.604,7.604,7.604h49.411
			c4.199,0,7.604-3.405,7.604-7.604v-90.228C288.311,217.143,284.907,213.739,280.707,213.739z M273.103,303.967H238.9V257.84
			h34.203V303.967z M273.103,242.633H238.9v-13.686h34.203V242.633z"/>
	</g>
</g>
<g>
	<g>
		<path d="M117.737,213.739H68.326c-4.199,0-7.604,3.405-7.604,7.604v90.228c0,4.199,3.404,7.604,7.604,7.604h49.411
			c4.199,0,7.604-3.405,7.604-7.604v-90.228C125.341,217.143,121.936,213.739,117.737,213.739z M110.133,303.967H75.93V257.84
			h34.203V303.967z M110.133,242.633H75.93v-13.686h34.203V242.633z"/>
	</g>
</g>
<g>
	<g>
		<path d="M199.223,334.635h-49.411c-4.199,0-7.604,3.405-7.604,7.604v90.228c0,4.199,3.404,7.604,7.604,7.604h49.411
			c4.199,0,7.604-3.405,7.604-7.604v-90.228C206.826,338.04,203.422,334.635,199.223,334.635z M191.619,424.863h-34.203v-46.13
			h34.203V424.863z M191.619,363.525h-34.203v-13.682h34.203V363.525z"/>
	</g>
</g>
<g>
	<g>
		<path d="M288.311,342.244v-0.005c0-4.199-3.405-7.604-7.604-7.604h-49.411c-4.199,0-7.604,3.405-7.604,7.604v90.228h0
			c0,4.199,3.405,7.604,7.604,7.604h49.411c4.199,0,7.604-3.405,7.604-7.604v-61.29c0-0.016,0.002-0.032,0.002-0.049v-28.885
			H288.311z M273.103,424.863H238.9v-46.13h34.203V424.863z M273.103,363.525H238.9v-13.682h34.203V363.525z"/>
	</g>
</g>
<g>
	<g>
		<path d="M117.737,334.635H68.326c-4.199,0-7.604,3.405-7.604,7.604v90.228c0,4.199,3.404,7.604,7.604,7.604h49.411
			c4.199,0,7.604-3.405,7.604-7.604v-90.228C125.341,338.04,121.936,334.635,117.737,334.635z M110.133,424.863H75.93v-46.13h34.203
			V424.863z M110.133,363.525H75.93v-13.682h34.203V363.525z"/>
	</g>
</g>
<g>
	<g>
		<path d="M362.193,92.843h-49.411c-4.199,0-7.604,3.405-7.604,7.604v90.228c0,4.2,3.405,7.605,7.604,7.605h49.411
			c4.199,0,7.604-3.405,7.604-7.604v-90.229C369.797,96.247,366.392,92.843,362.193,92.843z M354.589,183.072h-34.203v-46.123
			h34.203V183.072z M354.589,121.74h-34.203v-13.689h34.203V121.74z"/>
	</g>
</g>
<g>
	<g>
		<path d="M443.677,92.843h-49.411c-4.199,0-7.604,3.405-7.604,7.604v90.228c0,4.2,3.405,7.605,7.604,7.605h49.411
			c4.199,0,7.604-3.405,7.604-7.604v-90.229C451.281,96.247,447.877,92.843,443.677,92.843z M401.87,108.051h34.202v13.689H401.87
			V108.051z M436.073,183.072H401.87v-46.123h34.203V183.072z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

</C9IconBase>
